#output {
  flex-wrap: wrap;
  font-size: 48px;
  font-weight: bold;
  display: flex;
}

.gradient-letter {
  font-size: 24px;
  display: inline-block;
}

.color-input, .prefix-suffix-input {
  align-items: center;
  display: flex;
}

#text, #gradientCode {
  box-sizing: border-box;
  width: 100%;
}

.prefix-suffix-input input {
  width: 50%;
}

.prefix-suffix-label {
  width: 60px;
}

/*# sourceMappingURL=index.23cde164.css.map */
