#output {
  display: flex;
  flex-wrap: wrap;
  font-weight: bold;
  font-size: 48px;
}
.gradient-letter {
  font-size: 24px;
  display: inline-block;
}
.color-input, .prefix-suffix-input {
  display: flex;
  align-items: center;
}
#text, #gradientCode {
  width: 100%;
  box-sizing: border-box;
}
.prefix-suffix-input input {
  width: 50%;
}
.prefix-suffix-label {
  width: 60px;
}
